import './App.css';
import { numberOfEngines, getAllEngines, getEngineData, numberOfEnginesArchival } from './zoom/zoomMethods';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from "react-csv";
import md5 from 'md5';

function App() {
  const [engineDatas, setEngineDatas] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [passwordEntered, setpasswordEntered] = useState(true);
  const [searchedAdddress, setSearchedAdddress] = useState("");
  const [archivalEnginedata, setarchivalEnginedata] = useState([]);
  const [filteredEnginedatas, setfilteredEnginedatas] = useState([]);
  const [isArchivalDataLoading, setIsArchivalDataLoading] = useState(false);
  const [allEngineData, setallEngineData] = useState([]);
  const [actualEngineData, setactualEngineData] = useState({});

  const hash = process.env.REACT_APP_ENGINES_REPORT_PASSWORD_HASH;

  const inputRef = useRef("");
  const archivalInputRef = useRef("");

  useEffect(() => {
    const fetchData = async () => {
      const ids = await numberOfEngines()
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e, "error");
        });

      const addresses = await getAllEngines(ids);
      const engineDatas = await getEngineData(addresses);

      engineDatas.forEach((data, index) => {
        let path = [];
        if (data.referrer !== "0x0000000000000000000000000000000000000000") {
          path = [...engineDatas.find((item) => item.engineAddress === data.referrer).path];
          path.push(data.referrer);
        }
        data.path = path;
        engineDatas[index] = data;
      });

      setEngineDatas(engineDatas);
      setallEngineData(engineDatas);
      setisLoading(false);
    }

    if (!passwordEntered) {
      return;
    }
    fetchData();
  }, [passwordEntered]);

  const getDataByAddress = (address) => {
    const data = engineDatas.filter((item) => { return item.path.indexOf(address) > -1 || address === null });
    return data;
  }

  const handleInputChange = (e) => {
    const value = e.target.value;
    let searchedAddress = e.target.value;
    if (value.length === 6) {
      searchedAddress = engineDatas.find((item) => item.referralCode === value).engineAddress;;
    }
    inputRef.current.value = value;
    setSearchedAdddress(searchedAddress);
  }

  const generateData = () => {
    if (searchedAdddress.length !== 0) {
      const actualEngine = engineDatas.find((item) => item.engineAddress === searchedAdddress);
      setactualEngineData(actualEngine)

      const dataByAddress = getDataByAddress(searchedAdddress);
      setfilteredEnginedatas(dataByAddress);
    }
  }

  const csvData = [
    ["Engine Details"],
    ["engineId", "engine address", "numberOfNodes", "referrer", "referralCode", "numberOfReferrals", "paths"],
    [actualEngineData.engineId, actualEngineData.engineAddress, actualEngineData.numberOfNodes, actualEngineData.referrer, actualEngineData.referralCode, actualEngineData.numberOfReferrals, actualEngineData.path],
    [],
    ["engineId", "engine address", "numberOfNodes", "referrer", "referralCode", "numberOfReferrals", "paths"],
    ...filteredEnginedatas.map(({ engineId, engineAddress, numberOfNodes, referrer, referralCode, numberOfReferrals, path }) => [
      engineId, engineAddress, numberOfNodes, referrer, referralCode, numberOfReferrals, path
    ])]

  const passwordPrompt = () => {
    const password = window.prompt("Password:");
    if (password) {
      if (md5(password) === hash) {
        setpasswordEntered(true);
      } else {
        passwordPrompt();
      }
    } else {
      passwordPrompt();
    }
  };

  useEffect(() => {
    passwordPrompt();
  }, []);


  const fetchDataForArchival = async (blockNumber) => {
    const ids = await numberOfEnginesArchival(Number(blockNumber))
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e, "error");
      });

    const addresses = await getAllEngines(ids);
    const engineDatas = await getEngineData(addresses);

    engineDatas.forEach((data, index) => {
      let path = [];
      if (data.referrer !== "0x0000000000000000000000000000000000000000") {
        path = [...engineDatas.find((item) => item.engineAddress === data.referrer).path];
        path.push(data.referrer);
      }
      data.path = path;
      engineDatas[index] = data;
    });

    return engineDatas;
  }


  const handleArchiveInputChange = async (event) => {
    if (event.target.value !== "") {
      archivalInputRef.current.value = event.target.value;

      if (passwordEntered) {
        setIsArchivalDataLoading(true);
        const archivalEngineData = await fetchDataForArchival(archivalInputRef.current.value);
        setarchivalEnginedata(archivalEngineData);
        setIsArchivalDataLoading(false);
      }
    }
  }

  const csvDataByBlockNr = [["engineId", "engine address", "numberOfNodes", "referrer", "referralCode", "numberOfReferrals", "paths"],
  ...archivalEnginedata.map(({ engineId, engineAddress, numberOfNodes, referrer, referralCode, numberOfReferrals, path }) => [
    engineId, engineAddress, numberOfNodes, referrer, referralCode, numberOfReferrals, path
  ])]

  const csvDataForAllEngines = [["engineId", "engine address", "numberOfNodes", "referrer", "referralCode", "numberOfReferrals", "paths"],
  ...allEngineData.map(({ engineId, engineAddress, numberOfNodes, referrer, referralCode, numberOfReferrals, path }) => [
    engineId, engineAddress, numberOfNodes, referrer, referralCode, numberOfReferrals, path
  ])]

  return (
    <div className="app">
      {isLoading ?
        <div>
          <p style={{ color: "#ffffff" }}>Loading data, please wait</p>
          <div className="loadingSpinner"></div>
        </div>
        :
        (
          <div className='wrapper'>

            <div className="all-engines-holder">
              <button className="downloadButton">
                <CSVLink filename="all-engines-report.csv" data={csvDataForAllEngines} aria-multiline className="csv-link">
                  Collect All Engine Data
                </CSVLink>
              </button>
            </div>

            <div className='acutal-holder'>
              <label>Enter address or referral code:</label>
              <input type='text' ref={inputRef} onChange={(e) => handleInputChange(e)}></input>
              <button onClick={generateData} className={`${!inputRef.current.value ? "disabled" : ""} downloadButton`}>
                <CSVLink filename="engines-report.csv" data={csvData} className="csv-link">
                  Collect Engine Data
                </CSVLink>
              </button>
            </div>

            <div className="archival-holder">
              <label>Enter block number:</label>
              <input type="text" onChange={(e) => { handleArchiveInputChange(e) }} ref={archivalInputRef}></input>

              <button className={`${archivalEnginedata.length === 0 ? "disabled" : ""} downloadButton`}>
                {isArchivalDataLoading ? (<div className='loadingSpinner-mini'></div>) : (<CSVLink filename="archival-engines-report.csv" data={csvDataByBlockNr} className="csv-link">
                  Collect Archival Engine Data
                </CSVLink>)}

              </button>
            </div>
          </div>)}
    </div >

  );
}

export default App;

