import * as ethers from "ethers";
import { Zoom } from "zoom-next";
import zoom3 from "../zoom/abi/polygon-zoom-abi.json";
import abi from "../zoom/abi/engine-abi.json"

const zoom3Contract = "0x40faA8d164973Ab39F3cBf6e3876a3130F9c6C77"
const engineContract = "0xA42bC1F611FBf787FC2b42bA9dc091463b742b72"

let provider;
export const rpcProvider = () => {
    if (provider === undefined) {
        return (provider = new ethers.providers.JsonRpcProvider(
            `https://young-hidden-bush.matic.quiknode.pro/d8e09c96046dface11f6097f36fb45748fd8830a/`
        ));
    } else return provider;
};

export const getZoomContract = () => {
    const zoomContract = new ethers.Contract(
        zoom3Contract,
        zoom3.abi,
        rpcProvider()
    );
    return zoomContract;
};

const contract = new ethers.Contract(engineContract, abi.abi, rpcProvider());

export const numberOfEngines = async () => {
    const ids = await contract.nextEngineID().then().catch();
    return Number(ids-1);
}

export const numberOfEnginesArchival = async (blockNumber) => {
    const ids = await contract.nextEngineID({blockTag:blockNumber});
    return Number(ids);
} 

export const getAllEngines = async (ids) => {
    const zoomLibrary = new Zoom();
    const result = [];

    const batchSize = 100;
    const divisions = Math.ceil(ids / batchSize);

    for (let i = 0; i < divisions; i++) {
        const startIndex = i * batchSize + 1;
        const endIndex = Math.min((i + 1) * batchSize, ids);

        const contract = new ethers.Contract(engineContract, abi.abi, rpcProvider());
        const calls = [];
        for (let j = startIndex; j <= endIndex; j++) {
            const call = zoomLibrary.addCall(
                contract,
                ["allEngines", [j]],
                "allEngines(uint256) returns (address)"
            );
            calls.push(call);
        }

        const zoomQuery = zoomLibrary.getZoomCall();
        const combinedResult = await getZoomContract().combine(zoomQuery);
        zoomLibrary.resultsToCache(combinedResult, zoomQuery);

        calls.forEach((call) => {
            try {
                const decoded = zoomLibrary.decodeCall(call)[0];
                result.push(decoded);
            } catch (error) {
                console.log(error);
            }
        });
    }
    return result;
};

export const getEngineData = async (addresses) => {
    const zoomLibrary = new Zoom();
    let result = [];

    const batchSize = 100;
    const divisions = Math.ceil(addresses.length / batchSize);

    for (let i = 0; i < divisions; i++) {
        const startIndex = i * batchSize;
        const endIndex = Math.min((i + 1) * batchSize, addresses.length);

        const contract = new ethers.Contract(engineContract, abi.abi, rpcProvider());
        const calls = [];

        for (let j = startIndex; j < endIndex; j++) {
            const currentAddress = addresses[j];
            const call = zoomLibrary.addCall(
                contract,
                ["engineData", [currentAddress]],
                "engineData(address) returns (uint256, uint256, address, uint256, uint256)"
            );
            calls.push(call);
        }

        const zoomQuery = zoomLibrary.getZoomCall();
        const combinedResult = await getZoomContract().combine(zoomQuery);
        zoomLibrary.resultsToCache(combinedResult, zoomQuery);

        for (let k = 0; k < calls.length; k++) {
            const call = calls[k];
            try {
                const decoded = zoomLibrary.decodeCall(call);
                result.push({ ...decoded, engineAddress: addresses[startIndex + k] });
            } catch (error) {
                console.log(error);
            }
        }
    }

    return result;
};


